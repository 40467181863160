body {
  font-family: "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.3;
}

:root {
  --text-color: #000000;
  --text-font: 'proxima-nova';
  --background-gradient-start: #aaa;
  --background-gradient-end: #fff;
  --background: linear-gradient(var(--background-gradient-start), var(--background-gradient-end));
}

.center {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.center-row {
  display: flex;
  justify-content: center;
}

.LinkGallery__content .Button {
  font-family: var(--text-font), 'sans-serif' !important;
}

.LinkGallery__content .LinkGallery__content--buttons-transparent.Button {
  border-color: var(--text-color);
  color: var(--text-color);
}

.LinkGallery__content .LinkGallery__content--buttons-transparent.Button--secondary:not(.Button--action):not(:disabled):hover {
  background: var(--text-color);
  border-color: var(--text-color);
  color: var(--background-gradient-start);
}

.LinkGallery__content .LinkGallery__content--buttons-transparent.Button--secondary:not(.Button--action):not(:disabled):focus,
.LinkGallery__content .LinkGallery__content--buttons-transparent.Button--secondary:not(.Button--action):not(:disabled):active {
  background: none;
  border-color: var(--text-color);
  color: var(--text-color);
}

.LinkGallery__content .LinkGallery__content--buttons-filled.Button {
  border-color: var(--text-color);
  background: var(--text-color);
  color: var(--background-gradient-start);
}

.LinkGallery__content .LinkGallery__content--buttons-filled.Button--secondary:not(.Button--action):not(:disabled):hover {
  background: none;
  border-color: var(--text-color);
  color: var(--text-color);
}

.LinkGallery__content .LinkGallery__content--buttons-filled.Button--secondary:not(.Button--action):not(:disabled):focus,
.LinkGallery__content .LinkGallery__content--buttons-filled.Button--secondary:not(.Button--action):not(:disabled):active {
  border-color: var(--text-color);
  background: var(--text-color);
  color: var(--background-gradient-start);
}

.LinkGallery__content--buttons-squared {
  border-radius: 0;
}

.Username {
  cursor: default;
  color: var(--text-color);
}

.LinkGallery__content--social-icons a {
  margin: 0 10px;
}

.LinkGallery__content--social-icons svg {
  fill: var(--text-color);
}

.LinkGallery__content--social-icons > .A:active:not(:disabled) svg,
.LinkGallery__content--social-icons > .A:focus:not(:disabled) svg {
  fill: var(--text-color) !important;
}

.LinkGallery__content--social-icons > .A:hover:not(:disabled) svg {
  fill: var(--text-color) !important;
  opacity: .7;
}

/* TO REMOVE */
.LinkGallery__content--social-icons i:not(.website):not(.email) > svg {
  height: 20px!important;
  width: 20px!important;
}

.VideoWrapper {
  border: 1px solid var(--text-color);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
  /* Responsive wrapper for iframe (sources: https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php ; http://alistapart.com/article/creating-intrinsic-ratios-for-video) */
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.VideoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer > div:first-child {
  position: relative;
}

.footer p {
  color: var(--text-color);
}

.footer svg {
  fill: var(--text-color);
  height: 21px;
  width: 194px;
}

.footer .A:active:not(:disabled) svg,
.footer .A:focus:not(:disabled) svg,
.footer .A:hover:not(:disabled) svg {
  fill: var(--text-color)!important;
}

.LoaderPlaceholder {
  border-radius: 8px;
  background-color: var(--color-grey-50);
}

/* TIKTOK */
.TikTokLoader {
  height: 721px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .TikTokModal .ModalContent {
    min-height: 25vh;
    max-height: 75vh;
    height: auto;
  }
}
.TikTokModal .ModalContent {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.TikTokModal .TikTokVideo__title {
  padding: 36px 36px 12px;
}
.TikTokModal .TikTokVideo__wrapper {
  height: calc(100% - 70px);
}
.TikTokModal .TikTokVideo__content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px 36px;
}
.TikTokModal .TikTokVideo__buttons {
  border-top: 1px solid var(--color-grey-50);
  padding: 36px 0;
}

@media (max-width: 767px) {
  .TikTokModal.Modal--sm {
    height: calc(100% - 32px);
  }
}

/*
This CSS file not use css custom var because is used also
for component that aren't support by dashboard
 */

.AlertPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px auto;
  text-align: center;
}

.AlertPanel--sm {
  max-width: 400px;
}

.AlertPanel__title {
  color: #212732;
  /* color: var(--color-grey-800); */
  font-weight: 400;
  /* font-weight: var(--weight-normal); */
  margin-top: 24px;
}

.AlertPanel--sm .AlertPanel__title,
.AlertPanel--md .AlertPanel__title {
  font-size: 1.5rem;
  /* font-size: var(--display-medium); */
}

.AlertPanel--lg .AlertPanel__title {
  font-size: 2.25rem;
  /* font-size: var(--display-large); */
  font-weight: 300;
  /* font-weight: var(--weight-light); */
}

.AlertPanel__message {
  color: #595d66;
  /* color: var(--color-grey-600); */
  margin-top: 8px;
  line-height: 1.4;
}

.AlertPanel--sm .AlertPanel__message,
.AlertPanel--md .AlertPanel__message {
  font-size: 0.9375rem;
  /* font-size: var(--display-xsmall); */
}

@media (max-width: 767px) {
  .AlertPanel__cta {
    width: 100%;
  }

  .AlertPanel__cta * {
    width: 100%;
  }

  .AlertPanel__icon svg {
    width: 180px;
    height: 120px;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: var(--background) var(--background-gradient-start) no-repeat center;
  background-size: cover;
}

.App__content {
  flex: 1 1;
}

.Container--narrow {
  font-family: var(--text-font);
  flex-grow: 1;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.Page {
  margin-top: 48px;
  margin-bottom: 24px;
}

.Header {
  padding: 12px 0;
}

.footer {
  margin-top: 48px;
}

.footer svg {
  height: 21px;
  width: 194px;
}

@media (max-width: 731px) {
  .Container--narrow {
    width: calc(100% - 32px);
    margin-right: 16px;
    margin-left: 16px;
  }

  .Page {
    margin-top: 24px;
  }
}

