.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: var(--background) var(--background-gradient-start) no-repeat center;
  background-size: cover;
}

.App__content {
  flex: 1;
}

.Container--narrow {
  font-family: var(--text-font);
  flex-grow: 1;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.Page {
  margin-top: 48px;
  margin-bottom: 24px;
}

.Header {
  padding: 12px 0;
}

.footer {
  margin-top: 48px;
}

.footer svg {
  height: 21px;
  width: 194px;
}

@media (max-width: 731px) {
  .Container--narrow {
    width: calc(100% - 32px);
    margin-right: 16px;
    margin-left: 16px;
  }

  .Page {
    margin-top: 24px;
  }
}
